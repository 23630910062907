import React, {useEffect} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import HeatmapLayer from './HeatmapLayer';
import './leaflet.css';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {findMostCommonCoordinates} from "../../utils/getCenterAlert";

interface DataPoint {
    latitude: number;
    longitude: number;
    intensity?: number;
}

interface HeatmapProps {
    data: DataPoint[];
}

export const Heatmap: React.FC<HeatmapProps> = ({data}) => {
    const points: [number, number, number][] = data.map(item => [item.latitude, item.longitude, item.intensity || 1]);

    const [center, setCenter] = React.useState<[number, number]>([48.8583694, 2.2481174])
    const [centerIsLoading, setCenterIsLoading] = React.useState<boolean>(true)

    useEffect(() => {
        if (data) {
            setCenterIsLoading(true)
            const findCenter = findMostCommonCoordinates(data)
            console.log("center", findCenter)
            setCenter([findCenter?.latitude || 48.8583694, findCenter?.longitude || 2.2481174])
            setCenterIsLoading(false)
        }
    }, [data])

    return (
        <Box
            sx={{
                height: "620px",
                p: 2,
                borderRadius: "6px 0px 0px 6px",
                background: "url(images/daily-revenue.png)",
                backgroundColor: "#292929",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                backgroundSize: "cover",
                width: '70%'
            }}
        >
            <Stack>
                <Typography
                    variant="h5"
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pb: 1, pl: 1 }}
                >
                    Carte thermique
                </Typography>
            </Stack>
            {!centerIsLoading && <MapContainer center={center} zoom={13} style={{height: '550px', width: '100%'}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <HeatmapLayer points={points}/>
            </MapContainer>}
        </Box>

    );
};
