import {Edit} from "@refinedev/mui";
import {Box, TextField} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {IResourceComponentsProps, useTranslate} from "@refinedev/core";
import React from "react";

export const CompanyEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        register,
        formState: { errors },
    } = useForm();


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("id", {
                        required: "This field is required",
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("company.fields.id")}
                    name="id"
                    disabled
                />
                <TextField
                    {...register("nom", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.nom}
                    helperText={(errors as any)?.nom?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("company.fields.nom")}
                    name="nom"
                />
                <TextField
                    {...register("limitUser", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.limitUser}
                    helperText={(errors as any)?.limitUser?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("company.fields.limit")}
                    name="limitUser"
                />
            </Box>
        </Edit>
    );
};
