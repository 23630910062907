import {IResourceComponentsProps, useShow, useTranslate,} from "@refinedev/core";
import {DateField, NumberField, Show, TextFieldComponent as TextField,} from "@refinedev/mui";
import {Stack, Typography} from "@mui/material";
import React from "react";
export const CompanyShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    {translate("company.fields.id")}
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("company.fields.nom")}
                </Typography>
                <TextField value={record?.nom} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("company.fields.createdAt")}
                </Typography>
                <DateField value={record?.createdAt} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("company.fields.updatedAt")}
                </Typography>
                <DateField value={record?.updatedAt} />
            </Stack>
        </Show>
    );
};
