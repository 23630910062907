import { AuthBindings, Authenticated, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  AuthPage,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
} from "@refinedev/mui";
import { Title } from "./components/title/Title";

import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "./providers/nestjsx-crud";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Header } from "./components";
import { Dashboard } from "@mui/icons-material";
import DashboardHome from "./pages/dashboard";
import { UserList } from "./pages/user/UserList";
import { UserShow } from "./pages/user/UserShow";
import { UserEdit } from "./pages/user/UserEdit";
import { UserCreate } from "./pages/user/UserCreate";
import { postLogin } from "./api/auth.api";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AlertHistoryList } from "./pages/alert-history/AlertHistoryList";
import { AlertHistoryShow } from "./pages/alert-history/AlertHistoryShow";
import { CompanyList } from "./pages/entreprises/CompanyList";
import { CompanyShow } from "./pages/entreprises/CompanyShow";
import { CompanyEdit } from "./pages/entreprises/CompanyEdit";
import { CompanyCreate } from "./pages/entreprises/CompanyCreate";
import React, { useEffect } from "react";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import { OTAList } from "./pages/ota/OTAList";
import { OTACreate } from "./pages/ota/OTACreate";

const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    const { accessToken } = await postLogin({ email, password });
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    if (accessToken) {
      const { data: result } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/auth/me`
      );
      if (result.role === "ADMIN" || result.role === "MODERATOR") {
        localStorage.setItem("accessToken", accessToken);
        return { success: true, redirectTo: "/" };
      } else {
        localStorage.removeItem("accessToken");
        return { success: true, redirectTo: "/login" };
      }
    }
    return {
      success: false,
      error: { message: "Login Error", name: "Invalid email or password" },
    };
  },
  check: async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      return { authenticated: true };
    }
    return { authenticated: false };
  },
  logout: async () => {
    localStorage.removeItem("accessToken");
    return { success: true, redirectTo: "/login" };
  },
  onError: async () => ({}),
};

function App() {
  const API_URL = process.env.REACT_APP_BACKEND_URL || "";
  axios.defaults.baseURL = API_URL;

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: string) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const [isAllowed, setIsAllowed] = React.useState(false);

  const getUserRole = async () => {
    const { data: result } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/me`
    );
    setIsAllowed(result.role !== "ADMIN");
  };

  useEffect(() => {
    getUserRole();
  }, []);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <DevtoolsProvider>
              <Refine
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider(API_URL, axios)}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: false,
                  projectId: "L1l90k-oCaojV-G4dMBg",
                }}
                resources={[
                  {
                    name: "dashboard",
                    list: "/",
                    meta: {
                      label: t("dashboard.title"),
                      icon: <Dashboard />,
                    },
                  },
                  {
                    name: "users",
                    list: "/users",
                    show: "/users/:id",
                    create: "/users/create",
                    edit: "/users/edit/:id",
                    meta: {
                      label: t("users.title"),
                      icon: <GroupIcon />,
                    },
                  },
                  {
                    name: "alert-history",
                    list: "/alert-history",
                    show: "/alert-history/:id",
                    meta: {
                      label: t("alert-history.title"),
                      icon: <NotificationsActiveIcon />,
                    },
                  },
                  {
                    name: "company",
                    list: "/company",
                    show: "/company/:id",
                    create: "/company/create",
                    edit: "/company/edit/:id",
                    meta: {
                      hide: isAllowed,
                      label: t("company.title"),
                      icon: <ApartmentIcon />,
                    },
                  },
                  {
                    name: "ota",
                    list: "/ota",
                    create: "/ota/create",
                    meta: {
                      hide: isAllowed,
                      icon: <DeveloperBoardIcon />,
                    },
                  },
                ]}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="dashboard"
                        redirectOnFail="/login"
                        v3LegacyAuthProviderCompatible
                      >
                        <ThemedLayoutV2 Header={Header} Title={Title}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route index element={<DashboardHome />} />
                    <Route path="users">
                      <Route index element={<UserList />} />
                      <Route path=":id" element={<UserShow />} />
                      <Route path="edit/:id" element={<UserEdit />} />
                      <Route path="create" element={<UserCreate />} />
                    </Route>
                    <Route path="alert-history">
                      <Route index element={<AlertHistoryList />} />
                      <Route path=":id" element={<AlertHistoryShow />} />
                    </Route>
                    <Route path="company">
                      <Route index element={<CompanyList />} />
                      <Route path=":id" element={<CompanyShow />} />
                      <Route path="edit/:id" element={<CompanyEdit />} />
                      <Route path="create" element={<CompanyCreate />} />
                    </Route>
                    <Route path="ota">
                      <Route index element={<OTAList />} />
                      <Route path="create" element={<OTACreate />} />
                    </Route>
                  </Route>
                  <Route
                    path="login"
                    element={
                      <AuthPage
                        type="login"
                        title="Safee"
                        registerLink={false}
                      />
                    }
                  />
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
              <DevtoolsPanel />
            </DevtoolsProvider>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
