import React, { useMemo } from "react";
import { DeleteButton, List, useDataGrid } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useTable,
  useTranslate,
} from "@refinedev/core";
import { IconButton, colors, styled } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { enableOta } from "../../api/ota.api";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .safee-enabled": {
    backgroundColor: colors.green[700],
    "&:hover": {
      backgroundColor: colors.green[700],
    },
  },
}));

export const OTAList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid();

  const {
    tableQueryResult: { refetch },
  } = useTable();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "version",
        flex: 1,
        headerName: translate("ota.fields.version"),
        minWidth: 200,
      },
      {
        field: "createdAt",
        flex: 1,
        headerName: translate("ota.fields.createdAt"),
        minWidth: 200,
      },
      {
        field: "updatedAt",
        flex: 1,
        headerName: translate("ota.fields.updatedAt"),
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: translate("tables.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <IconButton
                aria-label="enable"
                sx={{ borderRadius: 1 }}
                color={"success"}
                style={{ padding: 4 }}
                onClick={() => {
                  console.log("enable", row);
                  enableOta(row.id).then(() => {
                    refetch();
                  });
                }}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
              <IconButton
                aria-label="download"
                sx={{ borderRadius: 1 }}
                onClick={() => {
                  window.open(row.fileURL, "_blank");
                }}
                style={{ padding: 4 }}
              >
                <DownloadIcon />
              </IconButton>
              <DeleteButton
                hideText
                recordItemId={row.id}
                style={{ padding: 4 }}
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 120,
      },
    ],
    [refetch, translate]
  );

  return (
    <List>
      <StyledDataGrid
        {...dataGridProps}
        getRowClassName={(params) =>
          params.row.enabled ? `safee-enabled` : ""
        }
        columns={columns}
        autoHeight
      />
    </List>
  );
};
