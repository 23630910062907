
export type LocationCoordinate = { latitude: number; longitude: number };
export type LocationDetails = {
    location: LocationCoordinate;
    formattedAddress: string;
    address_components: google.maps.GeocoderAddressComponent[];
};

export type AutocompletePrediction = google.maps.places.AutocompletePrediction;

const formatGeocoderResult = (
    data: google.maps.GeocoderResult,
): LocationDetails => {
    return {
        location: {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            latitude: data.geometry.location.lat as unknown as number,
            // eslint-disable-next-line @typescript-eslint/unbound-method
            longitude: data.geometry.location.lng as unknown as number,
        },
        formattedAddress: data.formatted_address,
        address_components: data.address_components,
    };
};

export const reverseGeocoding = async (
    latitude: number,
    longitude: number,
): Promise<LocationDetails> => {
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_APIKEY}`,
    );

    const data = (await response.json()) as google.maps.GeocoderResponse;

    return formatGeocoderResult(data.results[0]);
};
