import React from "react";
import {reverseGeocoding} from "../../utils/geoCoding";

function useAddress(latitude: number, longitude: number) {
    const [address, setAddress] = React.useState("");

    React.useEffect(() => {
        async function fetchAddress() {
            const result = await reverseGeocoding(latitude, longitude);
            console.log(result);

            setAddress(result.formattedAddress);
        }

        fetchAddress();
    }, [latitude, longitude]);

    return address;
}

export function AddressCell(props: { latitude: number; longitude: number; }) {
    const { latitude, longitude } = props;
    const address = useAddress(latitude, longitude);

    return <p>{address}</p>;
}
