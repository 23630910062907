interface Coordinates {
    latitude: number;
    longitude: number;
}

export function findMostCommonCoordinates(array: Coordinates[]): Coordinates | null {
    const coordCount: { [key: string]: number } = {};
    let maxCount = 0;
    let mostCommonCoordinates: Coordinates | null = null;

    array.forEach(coord => {
        const key = `${coord.latitude},${coord.longitude}`;
        if (!coordCount[key]) {
            coordCount[key] = 0;
        }
        coordCount[key]++;
        if (coordCount[key] > maxCount) {
            maxCount = coordCount[key];
            mostCommonCoordinates = coord;
        }
    });

    return mostCommonCoordinates;
}
