import {useTable} from "@refinedev/core";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import dayjs from "dayjs";
import {IUser} from "../../pages/user/UserList";
import React from "react";
import Stack from "@mui/material/Stack";
import 'dayjs/locale/fr'





interface INotification {
    createdAt: string;
    message: {
        en: string,
        fr: string,
    },
    sender: IUser,
    data: any
}


export const NotificationTimeline: React.FC = () => {

    const { tableQueryResult, current, setCurrent } =
        useTable<INotification>({
            resource: "notifications/timeline",
            initialSorter: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
            initialPageSize: 5,
            syncWithLocation: false,
        });

    const getColor = (data: any) => {
        if (data?.notificationType === "alert") {
            return "#CC525F";
        } else if (data?.journeyId) {
            return "#F9A52B";
        }
    }

    return (
        <Box
            sx={{
                p:2,
                pt:3,
                background: "url(images/daily-revenue.png)",
                backgroundColor: "#292929",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                backgroundSize: "cover",
                width: '40%',
                borderRadius: "0px 6px 6px 0px",
            }}
        >
            <Stack>
                <Typography
                    variant="h5"
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pb: 1, pl: 1 }}
                >
                    Timeline
                </Typography>
            </Stack>
            <Timeline position="right" sx={{ mt: 0, pt: 0 }}>
                {tableQueryResult?.data?.data.map(({ createdAt, message, sender, data }, index) => {
                    const color = getColor(data);
                    const dotColor = getColor(data);

                    return (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent sx={{ display: "none" }} />
                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    sx={{ borderColor: dotColor }}
                                />
                                <TimelineConnector sx={{ width: "1px" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box
                                    sx={{
                                        backgroundColor: color,
                                        borderRadius: 2,
                                        p: 1,
                                    }}
                                >
                                    <Tooltip
                                        arrow
                                        title={dayjs(createdAt).format("lll")}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{ fontStyle: "italic" }}
                                        >
                                            {dayjs(createdAt).format('L')}
                                        </Typography>
                                    </Tooltip>
                                    <Typography variant="subtitle2">
                                        {message.fr}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: "text.primary", fontSize: 12, fontWeight: 'light', mt: 1 }}
                                    >
                                        par {sender.fullname}
                                    </Typography>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
                {tableQueryResult?.data?.data.length === 0 && (
                    <Typography
                        variant="body1"
                        sx={{ color: "text.primary", fontSize: 12, fontWeight: 'light', mt: 1 }}
                    >
                        Aucune notification
                    </Typography>
                )}
                 <Box sx={{display: "flex", justifyContent: "center", mt: 1}}>
                    <Pagination
                        count={tableQueryResult?.data?.total}
                        page={current}
                        onChange={(e, page) => setCurrent(page)}
                        siblingCount={0}
                        boundaryCount={0}
                        size="small"
                        color="primary"
                    />
                </Box>
            </Timeline>
        </Box>
    );
};
