import React, {useState} from "react";
import {EditButton, EmailField, List, ShowButton, useDataGrid} from "@refinedev/mui";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {
    BaseRecord,
    CrudFilters,
    getDefaultFilter,
    HttpError,
    IResourceComponentsProps,
    useTranslate
} from "@refinedev/core";
import {Box, Button, Card, CardContent, CardHeader, Checkbox, Grid, TextField} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {Controller} from "react-hook-form";
import {Autocomplete} from "@mui/lab";
import axios from "axios";

export interface IUser {
    id: number,
    email: string,
    password: string,
    fullname: string
};

export interface IUserFilterVariables {
    q?: string;
    genre?: string;
    fullname?: string;
    hasBeenOnboarded?: boolean;
}

export const UserList: React.FC<IResourceComponentsProps> = () => {

    const translate = useTranslate();

    const {dataGridProps, search, filters} = useDataGrid<
        IUser,
        HttpError,
        IUserFilterVariables
    >({
        initialPageSize: 10,
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {q, genre, fullname, hasBeenOnboarded} = params;

            filters.push({
                field: "q",
                operator: "eq",
                value: q !== "" ? q : undefined,
            });

            filters.push({
                field: "genre",
                operator: "eq",
                value: genre !== "" ? genre : undefined ,
            });

            filters.push({
                field: "fullname",
                operator: "contains",
                value: fullname !== "" ? fullname : undefined,
            });

            filters.push({
                field: "hasBeenOnboarded",
                operator: "eq",
                value: hasBeenOnboarded,
            });

            return filters;
        },
        pagination: { mode: "client" },
    });

    const columns = React.useMemo<GridColDef<IUser>[]>(
        () => [
            {
                field: "email",
                flex: 1,
                headerName: "Email",
                minWidth: 250,
                renderCell: function render({value}) {
                    return <EmailField value={value}/>;
                },
            },
            {
                field: "fullname",
                flex: 1,
                headerName: "Fullname",
                minWidth: 200,
            },
            {
                field: "phone",
                flex: 1,
                headerName: "Phone",
                type: "number",
                headerAlign: "left",
                align: "left",
                minWidth: 100,
            },
            {
                field: "hasBeenOnboarded",
                headerName: "Onboarded",
                minWidth: 200,
                renderCell: function render({value}) {
                    return <Checkbox checked={!!value}/>;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({row}) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id}/>
                            <ShowButton hideText recordItemId={row.id}/>
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );


    const {register, handleSubmit, control} = useForm<
        BaseRecord,
        HttpError,
        IUserFilterVariables
    >({
        defaultValues: {
            hasBeenOnboarded: getDefaultFilter("hasBeenOnboarded", filters, "eq"),
            q: getDefaultFilter("q", filters, "eq"),
            fullname: getDefaultFilter("fullname", filters, "contains"),
            genre: getDefaultFilter("genre", filters, "eq"),
        },
    });

    const [file, setFile] = useState("");

    const onFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };

    const onFormSubmit = async (event: any) => {
        event.preventDefault();

        console.log(file)
        const formData = new FormData();
        formData.append('file', file);

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/csv-import`, formData)
            window.location.reload();
        } catch (e) {
            alert(translate('errors.import-failed'))
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
                <Card>
                    <CardHeader title="Filtres"/>
                    <CardContent sx={{pt: 0}}>
                        <Box
                            component="form"
                            sx={{display: "flex", flexDirection: "column"}}
                            autoComplete="off"
                            onSubmit={handleSubmit(search)}
                        >
                            <TextField
                                {...register("fullname")}
                                label="Recherche"
                                placeholder="Recherche une personne"
                                margin="normal"
                                fullWidth
                                autoFocus
                                size="small"
                            />
                            <Controller
                                control={control}
                                name="genre"
                                render={({ field }) => (
                                    // @ts-ignore
                                    <Autocomplete
                                        options={[
                                            'MASCULIN',
                                            'FEMININ',
                                            'AUTRES'
                                        ]}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return translate("user.fields.genre." + item);
                                        }}
                                        renderInput={(params) => (
                                            //@ts-ignore
                                            <TextField
                                                {...params}
                                                label={translate(
                                                    "user.filter.genre",
                                                )}
                                                placeholder={translate(
                                                    "user.filter.genre.placeholder",
                                                )}
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="hasBeenOnboarded"
                                render={({ field }) => (
                                    // @ts-ignore
                                    <Autocomplete
                                        options={[
                                           true,
                                            false
                                        ]}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return item
                                                ? 'Oui'
                                                : 'Non';
                                        }}
                                        renderInput={(params) => (
                                            //@ts-ignore
                                            <TextField
                                                {...params}
                                                label={translate(
                                                    "user.filter.active",
                                                )}
                                                placeholder={translate(
                                                    "user.filter.active",
                                                )}
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <br />
                            <Button type="submit" variant="contained">
                                {translate("user.filter.search")}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={{mt: 1}}>
                    <CardHeader title="Actions"/>
                    <CardContent sx={{pt: 0}}>
                        <form onSubmit={onFormSubmit}>
                            <TextField
                                label={translate("user.import")}
                                type="file"
                                fullWidth
                                onChange={onFileChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{mb: 1}}
                            />
                            <Button variant="contained" color="success" type="submit">
                                {translate("user.import")}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={9}>
                <List>
                    <DataGrid {...dataGridProps} columns={columns}
                              autoHeight filterModel={undefined}
                    />
                </List>
            </Grid>
        </Grid>

    );
};
