import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface Record {
    createdAt?: string;
    endedAt?: string;
}

export function getDuration(record: Record): string {
    if (record.createdAt && record.endedAt) {
        const start = dayjs(record.createdAt);
        const end = dayjs(record.endedAt);

        const difference = end.diff(start);

        const differenceDuration = dayjs.duration(difference);

        return differenceDuration.format('HH:mm:ss');
    } else {
        return ""
    }
}


