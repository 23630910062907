import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Line, LineChart, ResponsiveContainer, Tooltip} from "recharts";

import { ChartTooltip } from "./chartTooltip";
import React from "react";
import {useTranslate} from "@refinedev/core";

import backgroundImage from '../../assets/svg/person-alert.svg'
import dayjs from "dayjs";
import axios from "axios";


export const DailyAlerts: React.FC = () => {
    const t = useTranslate();
    const [dailyAlertsData, setDailyAlertsData] = React.useState<any[]>([])


    const getDailyAlerts = async () => {
        const start = dayjs().subtract(30, "days").startOf("day").format("YYYY-MM-DD")
        const {data: results} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications/stats/daily-alert?startDate=${start}`)


        const formattedData = Object.keys(results).map((key) => {
            return {
                date: new Date(key).toDateString(),
                title: 'Alert',
                value: results[key] as number
            }
        })

        console.log(formattedData)
        setDailyAlertsData(formattedData)
    }


    React.useEffect(() => {
        getDailyAlerts()
    }, [])


    return (
        <Box
            sx={{
                height: "230px",
                p: 1,
                background: `url(${backgroundImage})`,
                backgroundColor: "#292929",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                backgroundSize: "contain",
                mb: 2,
            }}
        >
            <Stack>
                <Typography
                    variant="h5"
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pt: 1, pl: 1 }}
                >
                    {t("dashboard.dailyAlerts")}
                </Typography>

            </Stack>
            <Box sx={{ height: "130px" }}>
                <ResponsiveContainer width="99%">
                    <LineChart data={dailyAlertsData} >
                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke="rgba(255, 255, 255, 0.5)"
                            dot={false}
                            strokeWidth={4}
                        />
                        <Tooltip content={<ChartTooltip  />} />
                    </LineChart>
                </ResponsiveContainer>
            </Box>

        </Box>
    );
};
