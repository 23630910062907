import {Create} from "@refinedev/mui";
import {Box, TextField} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {IResourceComponentsProps, useTranslate} from "@refinedev/core";

export const CompanyCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        formState: { errors },
    } = useForm();

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("nom", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.nom}
                    helperText={(errors as any)?.nom?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("company.fields.nom")}
                    name="nom"
                />
                <TextField
                    {...register("limitUser", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.limitUser}
                    helperText={(errors as any)?.limitUser?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("company.fields.limit")}
                    name="limitUser"
                />
            </Box>
        </Create>
    );
};
