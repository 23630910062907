import {IResourceComponentsProps, useNavigation, useShow, useTranslate,} from "@refinedev/core";
import {
    Card,
    CardContent,
    CardHeader,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import Avatar from "@mui/material/Avatar";
import {LocalPhone} from "@mui/icons-material";
import {getDuration} from "../../utils/getDuration";




export const AlertHistoryShow: React.FC<IResourceComponentsProps> = () => {


    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data } = queryResult;
    const theme = useTheme();
    const { goBack } = useNavigation();
    const isSmallOrLess = useMediaQuery(theme.breakpoints.down("sm"));
    const record = data?.data;
    const [duration, setDuration] = React.useState('0');

    useEffect(() => {
        if (record?.createdAt && record?.updatedAt) {
            const calculateDuration = getDuration({
                createdAt: record?.createdAt,
                endedAt:  record?.updatedAt,
            });
            setDuration(calculateDuration);
        }
    }, [record]);

    console.log(record)


    const greenIcon = L.icon({
        iconUrl: 'https://res.cloudinary.com/dlnimaboq/image/upload/v1702476006/oupbiebxhfkbc49lckoj.png',
        iconSize:     [24, 24], // size of the icon
        iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });


    return (
        <Stack spacing={2}>
            <Card>
                <CardHeader
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                    }}
                    title={
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h6">
                                {translate("alert.fields.alertId")}
                            </Typography>
                            <Typography variant="caption">{`#${
                                record?.id ?? ""
                            }`}</Typography>
                        </Stack>
                    }
                    avatar={
                        <IconButton onClick={goBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Stepper
                        nonLinear
                        activeStep={record?.alert[0]?.isBroadcasting ? 2 : 3}
                        orientation={isSmallOrLess ? "vertical" : "horizontal"}

                    >
                        <Step key={1}>
                            <StepLabel
                                optional={
                                    <Typography variant="caption">
                                        {duration}
                                    </Typography>
                                }
                                error={duration === '0'}
                            >
                                {translate("alert.fields.tracking-created")}
                            </StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel
                                optional={
                                    <Typography variant="caption">
                                        {record?.createdAt &&
                                            dayjs(record?.createdAt).format(
                                                "L LT",
                                            )}
                                    </Typography>
                                }
                                error={record?.createdAt === ''}
                            >
                                {translate("alert.fields.alarm-started")}
                            </StepLabel>
                        </Step>
                        <Step key={3}>
                            <StepLabel
                                optional={
                                    <Typography variant="caption">
                                        {record?.alert[0]?.isBroadcasting ? translate("alert.fields.is-broadcasting")
                                             : translate("alert.fields.duree") + ' ' + duration
                                        }
                                    </Typography>
                                }
                                error={record?.alert[0]?.isBroadcasting === null}
                            >
                                {translate("alert.fields.is-broadcasting")}
                            </StepLabel>
                        </Step>
                        <Step key={4} >
                            <StepLabel
                                optional={
                                    <Typography variant="caption">
                                        {record?.updatedAt &&
                                            dayjs(record?.updatedAt).format(
                                                "L LT",
                                            )}
                                    </Typography>
                                }
                                error={record?.updatedAt === ''}
                            >
                                {translate("alert.fields.tracking-ended")}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </CardContent>
            </Card>

            <Box sx={{ height: 400 }}>
                {record?.latitude && record?.longitude && <MapContainer center={[record?.latitude, record?.longitude]} zoom={20}
                               style={{height: '400px', width: '100%'}}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker icon={greenIcon}  position={[record?.latitude, record?.longitude]}>
                      <Popup>
                          Un texte ou des informations que vous souhaitez afficher.
                      </Popup>
                  </Marker>

                </MapContainer>}
            </Box>

            <Paper sx={{ padding: 2 }}>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent={isSmallOrLess ? "center" : "space-between"}
                >
                    <Stack
                        direction={isSmallOrLess ? "column" : "row"}
                        alignItems={"center"}
                        textAlign={isSmallOrLess ? "center" : "left"}
                        gap={2}
                    >
                        <Avatar
                            alt={record?.user?.fullname}
                            src={record?.user?.profilePicture}
                            sx={{ width: 100, height: 100 }}
                        />
                        <Box>
                            <Typography>Utilisateur</Typography>
                            <Typography variant="h6">
                                {record?.user?.fullname}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        gap={2}
                        flexWrap="wrap"
                        justifyContent="center"
                        alignItems="center"
                        sx={{p: 2, backgroundColor: '#36B37E', borderRadius: 2, color: 'white'}}
                    >
                        <LocalPhone sx={{ fontSize: 36 }} />
                        <div>
                            <Typography>Numéro de téléphone</Typography>
                            <Typography variant="h6">
                                {record?.user?.phone}
                            </Typography>
                        </div>

                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    );
};
