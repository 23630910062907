import React from "react";
import {DateField, List, ShowButton, useDataGrid,} from "@refinedev/mui";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {
    BaseRecord,
    CrudFilters,
    getDefaultFilter,
    HttpError,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import {Box, Button, Card, CardContent, CardHeader, Grid, TextField} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {AddressCell} from "../../components/table/AdressCell";
import {getDuration} from "../../utils/getDuration";
import {Controller} from "react-hook-form";
import {Autocomplete} from "@mui/lab";


export interface IAlertHistory {
    id: number;
    user: string;
    genre: string;
    hasBeenOnboarded: boolean;
};

export interface IAlertHistoryFilterVariables {
    q?: string;
    user?: string;
    genre?: string;
    hasBeenOnboarded?: boolean;
}

export const AlertHistoryList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {dataGridProps, search, filters} = useDataGrid<
        IAlertHistory,
        HttpError,
        IAlertHistoryFilterVariables
    >({
        initialPageSize: 10,
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {q, user, genre, hasBeenOnboarded} = params;

            filters.push({
                field: "q",
                operator: "eq",
                value: q !== "" ? q : undefined,
            });

            filters.push({
                field: "user.fullname",
                operator: "contains",
                value: user !== "" ? user : undefined,
            });

            filters.push({
                field: 'user.genre',
                operator: 'eq',
                value: genre !== "" ? genre : undefined,
            })

            filters.push({
                field: 'user.hasBeenOnboarded',
                operator: 'eq',
                value: hasBeenOnboarded ? hasBeenOnboarded : undefined,
            })

            return filters;
        },
        pagination: { mode: "client" },
    });


    const {register, handleSubmit, control} = useForm<
        BaseRecord,
        HttpError,
        IAlertHistoryFilterVariables
    >({
        defaultValues: {
            q: getDefaultFilter("q", filters, "eq"),
            user: getDefaultFilter("user.fullname", filters, "contains"),
            genre: getDefaultFilter("user.genre", filters, "eq"),
            hasBeenOnboarded: getDefaultFilter("user.hasBeenOnboarded", filters, "eq"),
        },
    });

    const userLocale = navigator.language; // Récupère la locale de l'utilisateur
    console.log("userLocale", userLocale);



    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "user",
                flex: 1,
                headerName: translate("alert-history.fields.user"),
                minWidth: 200,
                renderCell: function render({value}) {
                    return <p>{value.fullname}</p>

                },
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("alert-history.fields.createdAt"),
                minWidth: 150,
                renderCell: function render({value}) {
                    return <DateField locales={userLocale} value={value}/>;
                },

            },
            {
                field: "Adresse",
                flex: 1,
                headerName: translate("alert-history.fields.address"),
                type: "string",
                minWidth: 300,
                renderCell: function render(value) {
                    return <AddressCell latitude={value.row.latitude} longitude={value.row.longitude} />;
                }
            },
            {
                field: "duration",
                flex: 1,
                headerName: translate("alert-history.fields.duration"),
                minWidth: 50,
                renderCell: function render(value) {
                    const calculateDuration = getDuration({
                        createdAt: value?.row.createdAt,
                        endedAt:  value?.row.updatedAt,
                    });
                    return <p>{calculateDuration}</p>;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({row}) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id}/>
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate, userLocale],
    );


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
                <Card>
                    <CardHeader title="Filtres"/>
                    <CardContent sx={{pt: 0}}>
                        <Box
                            component="form"
                            sx={{display: "flex", flexDirection: "column"}}
                            autoComplete="off"
                            onSubmit={handleSubmit(search)}
                        >
                            <TextField
                                {...register("user")}
                                label="Recherche"
                                placeholder="Recherche une personne"
                                margin="normal"
                                fullWidth
                                autoFocus
                                size="small"
                            />
                            <Controller
                                control={control}
                                name="genre"
                                render={({ field }) => (
                                    // @ts-ignore
                                    <Autocomplete
                                        options={[
                                            'MASCULIN',
                                            'FEMININ',
                                            'AUTRES'
                                        ]}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return translate("user.fields.genre." + item);
                                        }}
                                        renderInput={(params) => (
                                            //@ts-ignore
                                            <TextField
                                                {...params}
                                                label={translate(
                                                    "user.filter.genre",
                                                )}
                                                placeholder={translate(
                                                    "user.filter.genre.placeholder",
                                                )}
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="hasBeenOnboarded"
                                render={({ field }) => (
                                    // @ts-ignore
                                    <Autocomplete
                                        options={[
                                            true,
                                            false
                                        ]}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return item
                                                ? 'Oui'
                                                : 'Non';
                                        }}
                                        renderInput={(params) => (
                                            //@ts-ignore
                                            <TextField
                                                {...params}
                                                label={translate(
                                                    "user.filter.active",
                                                )}
                                                placeholder={translate(
                                                    "user.filter.active",
                                                )}
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                )}
                            />
                            <br />
                            <Button type="submit" variant="contained">
                                Filtrer
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={9}>
                <List>
                    <DataGrid {...dataGridProps} columns={columns}
                              autoHeight filterModel={undefined}
                    />
                </List>
            </Grid>
        </Grid>
    );
};
