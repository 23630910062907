import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Bar, BarChart, ResponsiveContainer, Tooltip} from "recharts";

import {ChartTooltip} from "./chartTooltip";
import React from "react";
import {useTranslate} from "@refinedev/core";
import dayjs from "dayjs";
import axios from "axios";



export const CreatedTrajet: React.FC = () => {
   const t = useTranslate();

    const [dailyCreatedTrajet, setDailyCreatedTrajet] = React.useState<any[]>([])

    const getCreatedTrajet = async () => {
        const start = dayjs().subtract(15, "days").startOf("day").format("YYYY-MM-DD")
        const {data: results} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications/stats/created-trajet?startDate=${start}`)


        const formattedData = Object.keys(results).map((key) => {
            return {
                date: new Date(key).toDateString(),
                title: 'Trajet',
                value: results[key] as number
            }
        })

        setDailyCreatedTrajet(formattedData)
    }


    React.useEffect(() => {
        getCreatedTrajet()
    }, [])



    return (
        <Stack
            justifyContent="space-between"
            sx={{
                height: "230px",
                p: 1,
                background: "url(images/daily-order.png)",
                backgroundColor: "#292929",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: '100%',
                borderRadius: "6px",
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={2}
            >
                <Typography
                    variant="h5"
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pt: 2, pl: 1 }}
                >
                    {t("dashboard.createdTrajet")}
                </Typography>
                <Typography
                    sx={{ color: "#fff", fontWeight: 100, mb: 0, fontSize: '12px' }}
                >
                    {t("dashboard.createdTrajetSubtitle")}
                </Typography>

            </Stack>
            <Box sx={{ height: "130px" }}>
                <ResponsiveContainer width="99%">
                    <BarChart data={dailyCreatedTrajet} barSize={15}>
                        <Bar
                            type="natural"
                            dataKey="value"
                            fill="rgba(255, 255, 255, 0.5)"
                            radius={[4, 4, 0, 0]}
                        />
                        <Tooltip
                            cursor={{
                                fill: "rgba(255, 255, 255, 0.2)",
                                radius: 4,
                            }}
                            content={<ChartTooltip />}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Stack>
    );
};
