import { LoadingButton } from "@mui/lab";
import { Stack, Input, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FC, ReactNode } from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";

interface UploadProps {
  id: string;
  register: UseFormRegisterReturn<"file">;
  errors: FieldErrors<FieldValues>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  preview: ReactNode;
}

const Upload: FC<UploadProps> = ({
  id,
  errors,
  register,
  onChange,
  preview,
}) => {
  return (
    <Stack
      direction="row"
      gap={4}
      flexWrap="wrap"
      alignItems="center"
      sx={{ marginTop: "16px" }}
    >
      <label htmlFor={id}>
        <Input
          id={id}
          type="file"
          sx={{ display: "none" }}
          onChange={onChange}
        />
        <input id="file" {...register} type="hidden" />
        <LoadingButton
          loadingPosition="end"
          endIcon={<FileUploadIcon />}
          variant="contained"
          component="span"
        >
          Upload
        </LoadingButton>
        <br />
        {errors.images && (
          <Typography variant="caption" color="#fa541c">
            {errors.images?.message?.toString()}
          </Typography>
        )}
      </label>
      {preview}
    </Stack>
  );
};

export default Upload;
