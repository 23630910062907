import axios from "axios";

export const getOtaFileUploadSignedUrl = async (
  version: string,
  extension: string
) => {
  const { data } = await axios.post<{ url: string; path: string }>(
    `/ota/s3-signed-url/put`,
    { version, extension }
  );
  return data;
};

export const enableOta = async (id: number) => {
  const { data } = await axios.put(`/ota/${id}/enable`);
  return data;
};
