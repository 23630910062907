import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import Upload from "../../components/forms/Upload";
import { FieldValues } from "react-hook-form";
import { getOtaFileUploadSignedUrl } from "../../api/ota.api";

export const OTACreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { formLoading, onFinish },
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm();

  const file: File = watch("file");

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File = (target.files as FileList)[0];
    if (file.name.split(".").pop() !== "bin") {
      alert(`File extension must be .bin. ${file.name} is not valid`);
      return;
    }

    setValue("file", file, { shouldValidate: true });
  };

  const onFinishHandler = async (data: FieldValues) => {
    const { path, url } = await getOtaFileUploadSignedUrl(data.version, "bin");
    try {
      await fetch(url, {
        method: "PUT",
        body: file,
      });
      onFinish({ version: data.version, fileURL: path });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onFinishHandler),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("version", { required: "This field is required" })}
          onChange={(event) =>
            setValue("version", event.currentTarget.value, {
              shouldValidate: true,
            })
          }
          error={!!(errors as any)?.nom}
          helperText={(errors as any)?.nom?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("ota.fields.version")}
          name="nom"
        />
        <Upload
          id="file"
          register={register("file", {
            required: "This field is required",
          })}
          errors={errors}
          onChange={onChangeFile}
          preview={file?.name}
        />
      </Box>
    </Create>
  );
};
