import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import React, {useEffect} from "react";
import backgroundImage from "../../assets/svg/company-image.svg";
import {useTranslate} from "@refinedev/core";
import axios from "axios";

export interface ISalesChart {
    date: string;
    title: "Order Count" | "Order Amount";
    value: number;
}

export const NumberEmployee: React.FC = () => {
    const t = useTranslate();

    const [company, setCompany] = React.useState<any>({})
    const getCompany = async () => {
        const {data: results} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/company`)
        console.log(results)
        setCompany(results)
    }


    useEffect(() => {
        getCompany()
    }, [])

    return (
        <Box
            sx={{
                height: "230px",
                p: 1,
                background: `url(${backgroundImage})`,
                backgroundColor: "#292929",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                backgroundSize: "contain",
                width: '100%',
                borderRadius: "6px",
            }}
            flexDirection={"column"}
            display={"flex"}
            justifyContent={"space-between"}
        >
            <Stack>
                <Typography
                    variant="h5"
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pt: 2, pl: 1, opacity: 0.8 }}
                >
                    {(company && company?.nom) || "Darty"}
                </Typography>
            </Stack>
            <Stack>
                <Typography
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pt: 1, pl: 1, fontSize: '60px' }}
                >
                    {(company && company?.count )|| "54"}
                </Typography>
                <Typography
                variant="h5"
                    sx={{ color: "#fff", fontWeight: 700, mb: 0, pl: 1 }}
                >
                    {t("dashboard.numberEmployee-protected")}
                </Typography>
            </Stack>
        </Box>
    );
};
