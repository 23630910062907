import axios from "axios";

interface UserCredentials {
    email: string;
    password: string;
}
export const postLogin = async (credentials: UserCredentials) => {
    return axios
        .post<{ accessToken: string }>("/auth/login", credentials)
        .then((res) => res.data);
};
