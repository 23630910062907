import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.heat';

interface HeatmapLayerProps {
    points: [number, number, number][];
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ({ points }) => {
    const map = useMap();

    useEffect(() => {
        if (points && points.length > 0) {
            const heat = L.heatLayer(points, { radius: 25 }).addTo(map);
            return () => {
                map.removeLayer(heat);
            };
        }
    }, [map, points]);

    return null;
};

export default HeatmapLayer;
