import {IResourceComponentsProps, useShow, useTranslate} from "@refinedev/core";
import {BooleanField, DateField, EmailField, List, NumberField,} from "@refinedev/mui";
import {Grid, Paper, Stack, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import EditNoteIcon from '@mui/icons-material/EditNote';
import React from "react";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {getDuration} from "../../utils/getDuration";
import {AddressCell} from "../../components/table/AdressCell";


export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {queryResult} = useShow();
    const {data} = queryResult;


    const record = data?.data;

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("alert-history.fields.createdAt"),
                minWidth: 250,
                renderCell: function render({value}) {
                    return <DateField value={value}/>;
                },
            },
            {
                field: "Adresse",
                flex: 1,
                headerName: translate("alert-history.fields.address"),
                type: "string",
                minWidth: 250,
                renderCell: function render(value) {
                    return <AddressCell latitude={value.row.latitude} longitude={value.row.longitude} />;
                }
            },
            {
                field: "duration",
                flex: 1,
                headerName: translate("alert-history.fields.duration"),
                minWidth: 100,
                renderCell: function render({row}) {
                    return <p>{getDuration({
                        createdAt: row?.createdAt,
                        endedAt: row?.endedAt,
                    })}</p>;
                }
            }
        ],
        [translate],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
                <Paper sx={{p: 2, paddingX: {xs: 4, md: 2}}}>
                    <a href={`edit/${record?.id}`} style={{float: 'right'}}>
                        <EditNoteIcon sx={{color: 'white'}}/>
                    </a>
                    <Stack alignItems="center" spacing={1}>
                        <Avatar
                            src={record?.profilePicture}
                            sx={{width: 120, height: 120}}
                        />
                        <Typography variant="h6">
                            {record?.fullname}
                        </Typography>
                    </Stack>
                    <br/>
                    <Stack spacing={1}>
                        <Typography variant="body1" fontWeight="bold">
                            {translate("user.fields.phone")}
                        </Typography>
                        <NumberField value={record?.phone ?? ""}/>
                        <Typography variant="body1" fontWeight="bold">
                            {translate("user.fields.email")}
                        </Typography>
                        <EmailField value={record?.email}/>
                        <Typography variant="body1" fontWeight="bold">
                            {translate("user.fields.company")}
                        </Typography>
                        <Typography variant="body1">
                            {record?.entreprise?.nom}
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                            {translate("user.fields.active")}
                        </Typography>
                        <BooleanField value={record?.hasBeenOnboarded}/>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={9}>
                <List headerProps={{
                    title: translate("alert-history.title"),
                }}>
                    {record?.emergencyHistoryProprietary && <DataGrid
                        columns={columns}
                        rows={record?.emergencyHistoryProprietary}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 5},
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 100]}
                        pagination
                    />}
                </List>
            </Grid>
        </Grid>
    );
};
