import {Create} from "@refinedev/mui";
import {Box, FormControl, Grid, Input, TextField} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {IResourceComponentsProps, useNavigation, useTranslate} from "@refinedev/core";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import React, {useEffect} from "react";
import {Controller} from "react-hook-form";
import {Autocomplete} from "@mui/lab";

export interface IFile {
    name: string;
    percent: number;
    size: number;
    status: "error" | "success" | "done" | "uploading" | "removed";
    type: string;
    uid: string;
    url: string;
}

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const {goBack} = useNavigation()
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [allCompany, setAllCompany] = React.useState<any[]>([])

    useEffect(() => {
        const fetchAllCompany = async () => {
            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/company`)
            console.log(data)
            setAllCompany(data)
        }
        fetchAllCompany()
    }, [])

    const onhandleSubmit = async (data: any) => {
        let dataFormatted = data
        if (isAllowed) {
            dataFormatted = {
                ...data,
                entrepriseId: data.entrepriseId?.id
            }
        } else {
            dataFormatted = {
                ...data,
                entrepriseId: null
            }
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/create-user`, dataFormatted)
            goBack()
        } catch (e) {
            alert(translate('errors.limit-user.exceeded'))
        }
    }

    const imageInput = watch("profilePicture");

    const onChangeHandler = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {

        const target = event.target;
        const file: File = (target.files as FileList)[0];

        const filename = file.name;

        if (!filename) return undefined;

        const filenameindexOf: number = filename.lastIndexOf('.');
        const extension = filename.slice(filenameindexOf + 1);

        const { data } = await axios.get<{ url: string; path: string }>(
            `${process.env.REACT_APP_BACKEND_URL}/aws/s3-signed-url/put`,
            { params: { folder: 'profiles', extension } },
        );

        const { url, path } = data


        try {
            await fetch(url, { method: 'PUT', body: file });
        } catch (e) {
            console.log('ERROR', e)
        }


        const { name, size, type, lastModified } = file;

        // eslint-disable-next-line
        const imagePaylod: any = [
            {
                name,
                size,
                type,
                lastModified,
                url: path,
            },
        ];
        setValue("profilePicture", path);
    };

    const [isAllowed, setIsAllowed] = React.useState(false)

    const getUserRole = async () => {
        const {data: result} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/me`)
        return result.role === 'ADMIN'
    }

    useEffect(() => {
        getUserRole().then(role => setIsAllowed(role))
    }, [])

    return (
        <Create isLoading={formLoading} saveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(onhandleSubmit)
        }}>
            <Grid container gap={2}>
                <Grid item xs={12} md={4}>
                    <Stack
                        gap={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <label htmlFor="avatar-input">
                            <Input
                                id="avatar-input"
                                type="file"
                                sx={{
                                    display: "none",
                                }}
                                onChange={onChangeHandler}
                            />
                            <input
                                id="file"
                                {...register("profilePicture")}
                                type="hidden"
                            />
                            <Avatar
                                sx={{
                                    cursor: "pointer",
                                    width: {
                                        xs: "120px",
                                        md: "160px",
                                        lg: "200px",
                                    },
                                    height: {
                                        xs: "120px",
                                        md: "160px",
                                        lg: "200px",
                                    },
                                }}
                                src={
                                    (imageInput as IFile[]) &&
                                    (imageInput as IFile[])[0].url
                                }
                                alt="User Picture"
                            />
                        </label>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                            }}
                        >
                            {translate(
                                "users.fields.images.description",
                            )}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                            {translate("users.fields.images.validation")}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box
                        component="form"
                        sx={{ display: "flex", flexDirection: "column" }}
                        autoComplete="off"
                    >
                        <TextField
                            {...register("email", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.email}
                            helperText={(errors as any)?.email?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            label="Email"
                            name="email"
                        />
                        <TextField
                            {...register("fullname", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.fullname}
                            helperText={(errors as any)?.fullname?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="Fullname"
                            name="fullname"
                        />
                        <TextField
                            {...register("phone", {
                                required: "This field is required",
                            })}
                            error={!!(errors as any)?.phone}
                            helperText={(errors as any)?.phone?.message}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="phone"
                            label="Phone"
                            name="phone"
                        />
                        {isAllowed && <FormControl>
                            <Controller
                                control={control}
                                name="entrepriseId"
                                rules={{
                                    required: translate(
                                        "errors.required.field",
                                        {field: "entreprise"},
                                    ),
                                }}
                                render={({field}) => (
                                    <Autocomplete
                                        disablePortal
                                        {...field}
                                        options={allCompany}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return item.nom
                                                ? item.nom
                                                : "";
                                        }}
                                        isOptionEqualToValue={(
                                            option,
                                            value,
                                        ) =>
                                            value === undefined ||
                                            option?.id?.toString() ===
                                            (
                                                value?.id ?? value
                                            )?.toString()
                                        }
                                        renderInput={(params) => (
                                            //@ts-ignore
                                            <TextField
                                                {...params}
                                                label={translate(
                                                    "user.input.entreprise",
                                                )}
                                                placeholder={translate(
                                                    "user.input.entreprise.placeholder",
                                                )}
                                                variant="outlined"
                                                error={
                                                    !!errors.entreprise
                                                        ?.message
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </FormControl>}
                    </Box>
                </Grid>
            </Grid>

        </Create>
    );
};
