import {Heatmap} from "../components";
import {DailyAlerts} from "../components/dashboard/dailyAlerts";
import {CreatedTrajet} from "../components/dashboard/createdTrajet";
import Stack from "@mui/material/Stack";
import {NumberEmployee} from "../components/dashboard/numberEmployee";
import axios from "axios";
import React, {useEffect} from "react";
import {NotificationTimeline} from "../components/dashboard/notificationTimeline";


const DashboardHome = () => {
    const [heatmapData, setHeatmapData] = React.useState<any>([])

    const getAlerts = async () => {
        const {data: results} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/alert-history/heatmap`)
        setHeatmapData(results)
    }


    useEffect(() => {
        getAlerts()
    }, [])

    return (
        <div>
            <DailyAlerts/>
            <Stack
                direction="row"
                gap={2}
                sx={{pb: 2}}
            >
                <NumberEmployee/>
                <CreatedTrajet/>
            </Stack>
            <Stack
                direction="row"


            >
                {heatmapData.length > 0 && <Heatmap data={heatmapData}/>}
                <NotificationTimeline/>
            </Stack>
        </div>
    )
}

export default DashboardHome
